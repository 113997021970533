import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const isLocalAddress = require('is-local-address');

function initDatadog() {
  if (isLocalAddress(new URL(window.location.origin).hostname)) {
    // eslint-disable-next-line no-console
    console.log('localhost: not initing datadog');
    return;
  }

  /*
   * rum
   * https://docs.datadoghq.com/real_user_monitoring/browser/setup/
   * dont record sessions
   * https://docs.datadoghq.com/real_user_monitoring/session_replay/
   *
   * browser log collection
   * https://docs.datadoghq.com/logs/log_collection/javascript/
   */

  const ddConfig = {
    allowedTracingUrls: [
      /https:\/\/.*\.getpera\.com\/api\//,
    ],
    applicationId: __DATADOG_APPLICATION_ID__,
    clientToken: __DATADOG_CLIENT_TOKEN__,
    defaultPrivacyLevel: 'mask-user-input',
    env: 'www',
    forwardErrorsToLogs: true,
    forwardReports: 'all',
    service: 'www',
    sessionReplaySampleRate: 20,
    sessionSampleRate: 100,
    site: 'datadoghq.eu',
    telemetrySampleRate: 0,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    useSecureSessionCookie: true,
    version: `${__GIT_BRANCH__}-${__GIT_ABBREVIATED_SHA__}`,
  };

  datadogRum.init(ddConfig);
  datadogLogs.init(ddConfig);
}

initDatadog();
